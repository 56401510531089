body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiTableCell-root {
  padding: 8px !important;
}

.MuiContainer-root {
  padding: 12px !important;
}
.MuiIconButton-root {
  padding: 7px 7px !important;
}
.MuiPaper-elevation2 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0),
    0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
}
.MuiInput-formControl {
  margin-top: 0 !important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 0 !important;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 14px 0 !important;
}
.DateRangePicker {
  margin-bottom: 0 !important;
  margin-left: 5px;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #992a33 !important;
  border: 1px double #b7303b !important;
}
.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background-color: #eb4a57 !important;
  border: 1px double #b7303b !important;
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background-color: #eb6873 !important;
  color: #fff !important;
  border: 1px double #b7303b !important;
}
